<script setup lang="ts">
import { register } from 'swiper/element';
import { A11y, Autoplay, Navigation, Pagination } from 'swiper/modules';
import type { SwiperOptions } from 'swiper/types';
import swiperStyles from '@/assets/scss/vendors/_swiper.scss?inline';

register();

const props = defineProps({
  swiperOptions: {
    type: Object as () => SwiperOptions,
    default: () => ({}),
  },
});

const wrapperSwiper = ref<HTMLElement | null>(null);

const defaultOptions: SwiperOptions = {
  injectStyles: [
    swiperStyles,
    `
      .swiper-pagination-bullet {
        border: 1px solid;
        border-color: inherit;
      }
      .swiper-pagination-bullet-active {
        background-color: --swiper-custom-color-neutral-white;
      }
    `,
  ],
  modules: [A11y, Autoplay, Navigation, Pagination],
};

const mergedOptions: SwiperOptions = {
  ...defaultOptions,
  ...props.swiperOptions,
  injectStyles: [...(defaultOptions.injectStyles ?? []), ...(props.swiperOptions.injectStyles ?? [])],
  modules: [...(defaultOptions.modules ?? []), ...(props.swiperOptions.modules ?? [])],
};

onMounted(() => {
  if (wrapperSwiper.value) {
    Object.assign(wrapperSwiper.value, mergedOptions);
    (wrapperSwiper as any).value.initialize();
    const slides = document.querySelectorAll('swiper-slide');
    slides.forEach((slide) => slide.classList.remove('v-hidden'));
  }
});
</script>

<template>
  <swiper-container ref="wrapperSwiper" init="false">
    <div slot="container-start">
      <slot name="start" />
    </div>
    <slot />
    <div slot="container-end">
      <slot name="end" />
    </div>
  </swiper-container>
</template>
